import React from 'react';

import { useHistory } from 'react-router-dom';
import appWrapperStyles from './appWrapper.module.css';
import IconButton from '@material-ui/core/IconButton';

interface Props {
  route: string;
}

const MenubarButton: React.FunctionComponent<Props> = ({ children, route }) => {
  const history = useHistory();
  return (
    <IconButton
      component='span'
      size='small'
      color='secondary'
      onClick={() => {
        history.push(route);
      }}
      className={appWrapperStyles.iconButton}
    >
      {children}
    </IconButton>
  );
};

export default MenubarButton;
