import React, { createContext, useState, useEffect, useContext } from 'react';

import firebase from '../firebase';
import { AuthContext } from './AuthContext';

interface UserState {
  displayName?: string;
  email?: string;
  phoneNumber?: string;
  photoUrl?: string;
  password?: string;
}

interface UserContextType {
  userState: UserState;
  dirtyUser: UserState;
  updateUserState: (userState: UserState) => void;
  resetUser: () => void;
  submitUser: () => void;
}

export const UserContext = createContext<UserContextType>(
  {} as UserContextType
);

const UserProvider: React.FunctionComponent = ({ children }) => {
  const { loginStatus } = useContext(AuthContext);

  const [user, setUser] = useState<UserState>({});
  const [dirtyUser, setDirtyUser] = useState<UserState>({});

  const updateUserState = (userState: UserState) => {
    setDirtyUser((cur) => ({ ...cur, ...userState }));
  };

  const resetUser = () => setDirtyUser({});

  const submitUser = () => {
    const { displayName, email, photoUrl } = dirtyUser;
    const { currentUser } = firebase.auth();
    if (currentUser) {
      if (dirtyUser.displayName) {
        currentUser.updateProfile({ displayName });
      }
      if (email) {
        currentUser.updateEmail(email);
      }
      if (typeof photoUrl === 'string') {
        currentUser.updateProfile({ photoURL: photoUrl || null });
      }
    }
    setUser((cur) => ({ ...cur, ...dirtyUser }));
    resetUser();
  };

  useEffect(() => {
    if (loginStatus === 'LOGGED_IN') {
      const { displayName, email, phoneNumber, photoURL } =
        firebase.auth().currentUser || {};
      setUser({
        displayName: displayName || '',
        email: email || '',
        phoneNumber: phoneNumber || '',
        photoUrl: photoURL || '',
      });
    }
  }, [loginStatus]);

  return (
    <UserContext.Provider
      value={{
        userState: user,
        updateUserState,
        resetUser,
        dirtyUser,
        submitUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
