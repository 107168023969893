import React from 'react';

import wahleIcon from '../../media/images/whale.png';

import appWrapperStyles from './appWrapper.module.css';

import Power from '@material-ui/icons/PowerSettingsNew';
import MenubarButton from './MenubarButton';
import { useRouteMatch } from 'react-router-dom';

const Menubar = () => {
  const match = useRouteMatch();
  return (
    <div className={appWrapperStyles.menuBar}>
      <div id='menuLeft'>
        <MenubarButton route={match.path}>
          <img src={wahleIcon} height='36px' alt='logo' />
        </MenubarButton>
      </div>
      {!match.path.includes('login') && (
        <div id='menuRight'>
          <MenubarButton route={match.path + '/logout'}>
            <Power
              htmlColor='white'
              className={appWrapperStyles.iconButtonIcon}
            />
          </MenubarButton>
        </div>
      )}
    </div>
  );
};

export default Menubar;
