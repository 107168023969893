import { useEffect } from 'react';

type BodyBackgroundTypes =
  | 'mainBackground'
  | 'catBackground'
  | 'whiteBackground';

const setBodyBackground = (backgroundType: BodyBackgroundTypes) => {
  document.body.className = backgroundType;
};

const useBodyBackground = (backgroundType: BodyBackgroundTypes) => {
  useEffect(() => {
    setBodyBackground(backgroundType);
    return () => {
      setBodyBackground('mainBackground');
    };
  }, [backgroundType]);
};

export default useBodyBackground;
