import React, { useEffect, useState, useContext } from 'react';
import catStyles from '../css/cat.module.css';
import cx from 'classnames';
import { AuthContext } from '../Context/AuthContext';
import useBodyBackground from '../Hooks/useBodyBackground';

const Cat = () => {
  useBodyBackground('catBackground');
  const { showLogin } = useContext(AuthContext);
  const [phrase, setPhrase] = useState('');

  const handleKeypress = (event: KeyboardEvent) => {
    setPhrase((cur) => (cur += event.key));
  };

  useEffect(() => {
    document.body.addEventListener('keypress', handleKeypress);
    return () => {
      document.body.removeEventListener('keypress', handleKeypress);
    };
  }, []);

  useEffect(() => {
    if (phrase.includes('meowmeow')) {
      showLogin();
    }
  }, [phrase, showLogin]);
  return (
    <>
      <div className={catStyles.spaceer} />
      <div className={catStyles.cat}>
        <div className={cx(catStyles.ear, catStyles['ear--left'])} />
        <div className={cx(catStyles.ear, catStyles['ear--right'])} />
        <div className={catStyles.face}>
          <div className={cx(catStyles.eye, catStyles['eye--left'])}>
            <div className={catStyles['eye-pupil']} />
          </div>
          <div className={cx(catStyles.eye, catStyles['eye--right'])}>
            <div className={catStyles['eye-pupil']} />
          </div>
          <div className={catStyles.muzzle} />
        </div>
      </div>
    </>
  );
};

export default Cat;
