import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn:
    'https://7f32dbbf81eb489f95ad6c4e1d99f94b@o434225.ingest.sentry.io/5391183',
});

const isIE = () => {
  var userAgent = navigator.userAgent;
  return userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1;
};

if (isIE()) {
  window.location.href = 'https://chrome.google.com';
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
