import React, { Suspense } from 'react';

import Loader from './Components/Loader';

import './css/App.css';
import AuthProvider from './Context/AuthContext';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import AppWrapper from './Components/AppWrapper/AppWrapper';
import UserProvider from './Context/UserContext';
import Login from './Components/Login/Login';
import Cat from './Components/Cat';

const AuthedRoutes = React.lazy(() => import('./AuthedRoutes'));

const App = () => (
  <BrowserRouter>
    <AuthProvider>
      <UserProvider>
        <Switch>
          <Route path='/authed'>
            <AppWrapper>
              <Suspense fallback={<Loader />}>
                <AuthedRoutes />
              </Suspense>
            </AppWrapper>
          </Route>
          <Route path='/login'>
            <AppWrapper>
              <Login />
            </AppWrapper>
          </Route>
          <Route path='/'>
            <Cat />
          </Route>
        </Switch>
      </UserProvider>
    </AuthProvider>
  </BrowserRouter>
);

export default App;
