import firebase from 'firebase/app';
import 'firebase/firebase-auth';
import 'firebase/database';

const config = {
  apiKey: 'AIzaSyCGIdY3JCyTQkK-fb_5qDJfWCzxQv_eZgQ',
  authDomain: 'wahldash.firebaseapp.com',
  databaseURL: 'https://wahldash.firebaseio.com',
  projectId: 'wahldash',
  storageBucket: 'wahldash.appspot.com',
  messagingSenderId: '456512753480',
  appId: '1:456512753480:web:30632b1ca8d73c66',
};
firebase.initializeApp(config);

export default firebase;
